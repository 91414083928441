<template>
  <Layout :top-bar-title="title" :activeBackButton="false" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <v-form submit.prevent="saveForm" ref="form">
      <BreadcrumbsField :items="breadcrumbItems"/>
      <h2 class="px-3">{{formTitle}}</h2>
      <CustomView
        v-if="showForm"
        viewName="groups_detail"
        :value="currentItem"
        @input="updateItem($event)"
        :custom-component-catalog="customComponentCatalog"
        :options="options"
      />
      <v-layout>
        <v-spacer />
        <v-btn
          class="ma-2"
          :loading="loadingSave"
          :disabled="loadingSave"
          color="primary"
          @click="loader = 'loadingSave'"
        >
          <v-icon left dark>fa-save</v-icon>
          {{ $t('general.save') }}
        </v-btn>
        <v-btn
          class="ma-2"
          :loading="loadingSaveAndContinue"
          :disabled="loadingSaveAndContinue"
          color="primary"
          @click="loader = 'loadingSaveAndContinue'"
        >
          <v-icon left dark>fa-save</v-icon>
          {{ $t('customActions.saveAndContinue') }}
        </v-btn>
      </v-layout>
    </v-form>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '../../dido/views/defaultNavigationDrawerSections'
import { mapActions, mapState } from 'vuex'
import CustomView from '@/lib/uncustomui/components/CustomView'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import { BASE_URL } from '@/variables'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'

export default {
  components: { Layout, CustomView, BreadcrumbsField },
  data () {
    return {
      title: this.$t('general.client'),
      sections: sections,
      distributorId: this.$route.params.id,
      loader: null,
      loadingSave: false,
      loadingSaveAndContinue: false,
      dialog: false,
      options: {
        context: {
          campaignId: this.distributor_id_or_default,
          serverUrl: BASE_URL
        }
      },
      customComponentCatalog: {},
      showForm: false
    }
  },
  created () {
    if (this.distributorId !== 'new') {
      this.getDistributor(this.distributorId)
        .finally(() => { this.showForm = true })
    } else {
      this.updateCurrentItem({ active: true })
      this.showForm = true
    }
  },
  methods: {
    ...mapActions('groupsCatalog', ['getDistributor', 'createDistributor', 'putFormItem', 'updateCurrentItem']),
    ...mapActions({ addNotification: 'addNotification' }),
    distributor_id_or_default () {
      let currentId = this.$route.params.id
      if (currentId === null) {
        return -1
      } else {
        return currentId
      }
    },
    async saveForm (redirect) {
      if (this.$refs.form.validate()) {
        await this.addPropFields()
        if (this.currentItem.id === undefined) {
          this.createDistributor()
            .then(response => {
              this.displayNotification(response)
              if (redirect) {
                this.$router.push({ name: 'GroupsList' })
              } else {
                const distributorId = response.data.id
                this.getDistributor(distributorId)
                this.$router.push({ name: 'GroupsDetail', params: { id: distributorId } })
              }
            })
        } else {
          this.putFormItem(this.currentItem)
            .then(response => {
              this.displayNotification(response)
              if (redirect) {
                this.$router.push({ name: 'GroupsList' })
              }
            })
        }
      }
      this.stopLoadingButton()
    },
    async displayNotification (response) {
      if (response.status === 403) {
        await this.addNotification(createWarningNotification(this.$t('general.permissionsError')))
      } else if (response.status === 200) {
        await this.addNotification(createSuccessNotification(this.$t('dialogs.successUpdate')))
      } else {
        await this.addNotification(createErrorNotification(this.$t('dialogs.errorWhenSaving')))
      }
    },
    stopLoadingButton () {
      this.loadingSave = false
      this.loadingSaveAndContinue = false
    },
    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    },
    async addPropFields () {
      Object.keys(this.currentItem).forEach(key => {
        if (key.startsWith('prop__')) {
          this.currentItem.props[key.replace('prop__', '')] = this.currentItem[key]
        }
      })
    }
  },
  computed: {
    ...mapState('groupsCatalog', ['currentItem']),
    itemsBreadcrumbs () {
      return [
        {
          text: this.$t('customer.list'),
          disabled: false,
          name: 'GroupsList'
        },
        {
          text: this.$t('customer.new'),
          disabled: true
        }
      ]
    },
    formTitle () {
      return this.currentItem.external_id !== undefined ? this.currentItem.external_id : this.$t('general.newSecondary')
    },
    breadcrumbItems () {
      return this.currentItem.distributor_items_breadcrumbs ? this.currentItem.distributor_items_breadcrumbs : this.itemsBreadcrumbs
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      if (l === 'loadingSave') {
        this.saveForm(true)
      } else if (l === 'loadingSaveAndContinue') {
        this.saveForm(false)
      }

      this.loader = null
    }
  }
}
</script>
